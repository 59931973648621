import Vue from "vue";
import VueScrollTo from 'vue-scrollto';
import 'element-closest-polyfill';
Vue.use(VueScrollTo);

export default function registerVueDirectives() {
  Vue.directive('class-toggler', {
    inserted(el, {
      modifiers: {
        parent,
        closest,
        body,
        prevent
      },
      arg,
      value
    }) {
      el.clickCallback = (e) => {
        // v-class-toggler.prevent="'some-class'"
        let valueClass = value.class ? value.class : value;
        if (prevent) {
          e.preventDefault();
        }
        let target;
        switch (true) {
          // v-class-toggler.parent="'some-class'"
          case parent:
            target = el.parentNode;
            if (value.clickOtsideClose) {
              target.addEventListener('click', (e) => {
                e.stopPropagation();
              });
              document.body.addEventListener('click', () => {
                target.classList.remove(valueClass);
              });
            }
            break;
            // v-class-toggler:closest-el-class.closest="'some-class'"
          case closest:
            target = arg ? el.closest(`.${arg}`) : el;
            break;
          case body:
            target = document.body;
            if (value.clickOtsideClose) {
              el.addEventListener('click', (e) => {
                e.stopPropagation();
              });
              document.querySelector(value.preventPropagationSelector).addEventListener('click', (e) => {
                e.stopPropagation();
              });
              target.addEventListener('click', () => {
                target.classList.remove(valueClass);
              });
            }
            break;
            // v-class-toggler="'some-class'"
          default:
            target = el;
        }
        if (!target) {
          return;
        }

        target.classList.toggle(valueClass);
      };
      el.addEventListener('click', el.clickCallback);
    },
    unbind(el) {
      el.addEventListener('click', el.clickCallback);
    },
  });
  Vue.directive('lazy-reload', {
    bind(el) {
      el.clickCallback = (e) => {
        e.preventDefault();
        VueScrollTo.scrollTo(document.body, 300, {
          onDone: function() {
            window.location = el.getAttribute('href');
          },
        })
      };
      el.addEventListener('click', el.clickCallback);
    },
    unbind(el) {
      el.addEventListener('click', el.clickCallback);
    },
  });
}
