<template>
  <form action="" ref="consult_form" class="form consult-form__form" @submit.prevent="submit">
    <h2 class="form__title">Скачать расчёт</h2>
    <div class="form__text"><p>Для скачивания формы укажите данные</p></div>
    <div class="form__row">
      <input type="text" class="form__textinput" name="name" placeholder="ФИО" :class="{ 'form__textinput_error': $v.form.name.$error }" v-model="form.name">
    </div>
    <div class="form__row form__row_flex form__row_big-margin">
      <div class="form__col">
        <input type="text" class="form__textinput" name="phone" v-mask="'+7 ### ### ## ##'" placeholder="Ваш телефон" :class="{ 'form__textinput_error': $v.form.phone.$error }" v-model="form.phone">
      </div>
      <div class="form__col">
        <input type="text" class="form__textinput" name="email" placeholder="Ваш e-mail" :class="{ 'form__textinput_error': $v.form.email.$error }" v-model="form.email">
      </div>
    </div>
    <div class="form__row form__row_flex">
      <div class="form__col">
        <label class="form__checkbox" :class="{ 'form__checkbox_error': $v.form.agree.$error }">
          <input type="checkbox" v-model="form.agree" @change="$v.form.agree.$touch()">
          <span class="form__checkbox-box"></span>
          <span class="form__checkbox-label">Я согласен с <a href="/upload/processing_conditions.pdf">условиями обработки данных</a></span>
        </label>
      </div>
      <div class="form__col">
        <input class="btn btn_pink" type="submit" value="Скачать">
      </div>
    </div>
  </form>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: 'DownloadForm',
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        agree: false,
      },
    }
  },
  validations: {
    form: {
      name: { required, min: minLength(3) },
      email: { required, email },
      phone: { required, min: minLength(16) },
      agree: { sameAs: sameAs( () => true ) },
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      } else {
        alert('Form submitted');
        this.$refs.consult_form.submit();
      }
    }
  },
}
</script>
